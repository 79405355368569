<template>
  <div class="page">
    <div class="top-container" @click="handlePreviewMap">
      <img
        src="https://img.miaocang.cc/pcImg/cloud/shopping-guide/index/img_shouye_top@2x.png"
        class="top-image"
        oncontextmenu="return false;"
      />
    </div>
    <div class="bottom-container">
      <div class="first-row">
        <img src="https://img.miaocang.cc/pcImg/cloud/shopping-guide/index/box_part01@2x.png" class="btn" oncontextmenu="return false;" @click="toShoppingGuideRoutePage(1)" />
        <img src="https://img.miaocang.cc/pcImg/cloud/shopping-guide/index/box_part02@2x.png" class="btn" oncontextmenu="return false;" @click="toShoppingGuideRoutePage(2)" />
      </div>
      <div class="second-row">
        <img src="https://img.miaocang.cc/pcImg/cloud/shopping-guide/index/box_part03@2x.png" class="btn" oncontextmenu="return false;" @click="toShoppingGuideRoutePage(3)" />
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import imgSrc from '@/utils/bigDataIMG.js'
const bigDataIMG = imgSrc.data()

export default {
  name: "ShoppingGuideIndex",

  created() {
    document.title = '花木产业导购图'
  },

  methods: {
    /* 顶部区域的点击事件处理: 查看地图的大图预览 */
    handlePreviewMap() {
      ImagePreview({
        images: [bigDataIMG.shopping_guide_map],
        closeable: true,
        showIndex: false,
        maxZoom: 10,
        overlayStyle: {
          'background-color': 'rgba(0, 0, 0, .6)'
        },
        getContainer() {
          return document.querySelector('.page')
        }
      })
    },

    /* 跳转到花木产业导购图路线页 */
    toShoppingGuideRoutePage(num) {
      console.log('toShoppingGuideRoutePage')
      this.$router.push({
        name: 'ShoppingGuideRoute',
        query: {
          num
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
  background-color: #f3d7b3;
  display: flex;
  flex-direction: column;
  .top-container {
    height: calc((100vw - 80px) * 1.2975);
    max-height: calc((840px - 80px) * 1.2975);
    .top-image {
      pointer-events: auto;
      object-position: 0 -80px;
    }
  }
  .bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100vw * 0.87);
    max-height: calc(840px * 0.87);
    background-image: url('https://img.miaocang.cc/pcImg/cloud/shopping-guide/index/img_shouye_down@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .first-row {
     display: flex;
     justify-content: space-around; 
     align-items: center;
     margin-bottom: 32px;
     .btn {
       width: calc(100vw * 0.376);
       max-width: calc(840px * 0.376);
       height: calc(100vw * 0.376 * 0.723);
       max-height: calc(840px * 0.376 * 0.723);
      }
    }
    .second-row {
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        width: calc(100vw * 0.441);
        max-width: calc(840px * 0.441);
        height: calc(100vw * 0.441 * 0.628);
        max-height: calc(840px * 0.441 * 0.628);
      }
    }
  }
  img {
    width: 100%;
    user-select: none;
    // pointer-events: none;
  }
}
</style>
